<template>
  <div class="py-16 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="text-base font-semibold tracking-wide text-action">
          {{ heading }}
        </h2>
        <p
          class="
            mt-2
            text-2xl
            leading-8
            font-extrabold
            tracking-tight
            dark-text
            sm:text-3xl
          "
        >
          {{ title }}
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          {{ subtitle }}
        </p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt class="flex">
              <Icon :icon="['fas', 'star']" class="m-1 text-primary" />
              <p class="ml-10 text-lg leading-6 font-medium text-gray-900">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BodySection',
    props: ['heading', 'title', 'subtitle', 'features']
  };
</script>
