<template>
  <div class="relative bg-white overflow-hidden shadow-sm">
    <div class="max-w-7xl mx-auto py-10 lg:py-0">
      <div
        class="
          relative
          z-10
          py-2
          pb-8
          bg-white
          sm:pb-16
          md:pb-20
          lg:max-w-2xl lg:w-full lg:pb-28
          xl:pb-32
        "
      >
        <svg
          class="
            hidden
            lg:block
            absolute
            right-0
            inset-y-0
            h-full
            w-48
            text-white
            transform
            translate-x-1/2
          "
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>

        <main
          class="
            mx-auto
            max-w-7xl
            px-4
            sm:mt-12 sm:px-6
            md:mt-16
            lg:mt-20 lg:px-8
            xl:mt-28
          "
        >
          <div class="sm:text-center lg:text-left">
            <h1
              class="
                text-3xl
                tracking-tight
                font-extrabold
                sm:text-2xl
                md:text-5xl
                dark-text
              "
            >
              <span class="block xl:inline">Buy & Sell pre-owned items</span>
              {{ ' ' }}
              <span class="block xl:inline text-primary">with confidence</span>
            </h1>
            <p
              class="
                mt-3
                text-base text-gray-500
                sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                md:mt-5 md:text-xl
                lg:mx-0
              "
            >
              Peddle marketplace is a mobile app that enables you buy and sell items with
              people in your local and extended communities while ensuring that the
              quality of items bought are as expected.
            </p>
            <div class="mt-5 flex justify-center items-center lg:justify-start space-x-6">
              <div class="rounded-md w-52">
                <a href='https://play.google.com/store/apps/details?id=com.peddle.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
              </div>
              <div class="rounded-md p-4 rounded-md">
                <a
                  href="https://apps.apple.com/au/app/peddle-marketplace/id1615801779?itsct=apps_box_promote_link&itscg=30200"
                  alt='Download on the App Store'
                  target="_blank"
                >
                  <img src="/app_store_badge.svg" class="w-44"/>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div
      class="
        flex
        items-center
        justify-center
        h-96
        lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2
        bg-primary
        lg:h-auto
      "
    >
      <img class="mx-auto w-auto h-full object-cover" src="heroAltBg.jpeg" alt="" />
    </div>
  </div>
</template>

<script>
  const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' }
  ];

  export default {
    components: {},
    setup() {
      return {
        navigation
      };
    }
  };
</script>
