<template>
  <div class="flex flex-col flex-grow">
    <div class="flex-grow">
      <HeroSection />
      <BodySection
        :heading="buy.heading"
        :title="buy.title"
        :subtitle="buy.subtitle"
        :features="buy.features"
      />
      <BodySection
        :heading="sell.heading"
        :title="sell.title"
        :subtitle="sell.subtitle"
        :features="sell.features"
      />
      <BodySection
        :heading="request.heading"
        :title="request.title"
        :subtitle="request.subtitle"
        :features="request.features"
      />
    </div>
  </div>
</template>

<script>
  import HeroSection from '@/components/sections/HeroSection.vue';
  import BodySection from '@/components/sections/BodySection.vue';

  export default {
    name: 'home',
    components: {
      HeroSection,
      BodySection
    },
    data() {
      return {
        buy: {
          heading: 'Buy',
          title: 'Find great quality items near you',
          subtitle:
            'Join our sharing community and discover great quality pre-owned items at affordable prices.',
          features: [
            {
              name: 'Great quality products',
              description:
                "All products listed meet Peddle's minimum working condition requirement which sets a quality standard for products on the platform."
            },
            {
              name: 'Verified sellers',
              description:
                'Sellers are verified before listing products to ensure safety and accountability on the platform.'
            },
            {
              name: 'Instant collection',
              description:
                'Arrange a meetup and collect your item(s) minutes after purchasing them.'
            },
            {
              name: 'Money back guarantee',
              description:
                'If for any reason the product could not be successfully collected from a seller, rest assured that your payment will be refunded in full.'
            }
          ]
        },
        sell: {
          heading: 'Sell',
          title: 'Get paid for your pre-loved items',
          subtitle:
            'Letting go can be rewarding with Peddle, trade in valued items you no longer need for money.',
          features: [
            {
              name: 'Supercharge your garage sale',
              description:
                'Having a garage sale? List your items on Peddle and buyers can buy in advance and collect at your location.'
            },
            {
              name: 'Declutter with ease',
              description:
                'Simply list items you no longer need and eager buyers can take those valueable items off your hands.'
            },
            {
              name: 'Simple pricing',
              description:
                'List a sell price that reflects the condition of the item(s) and get paid exactly that, no bidding or bargaining.'
            },
            {
              name: 'Make money',
              description:
                'Get paid straight to your account within 1 to 3 days of a successful sale.'
            }
          ]
        },
        request: {
          heading: 'Request',
          title: "Don't search the web for bargains, just ask!",
          subtitle:
            "Post a request to let others know what you're looking for and sellers with similar items may list them for sale. You never know what bargains await.",
          features: [
            {
              name: 'Find deals',
              description:
                'Let the community know what you need and get notified when similar items are listed for sale.'
            },
            {
              name: 'Sell faster',
              description:
                "See a list of items people need in your extended community and sell what's in demand."
            }
          ]
        }
      };
    }
  };
</script>

<style scoped></style>
